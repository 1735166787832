.plan {
  @apply flex flex-row justify-between mt-6  space-x-5;
}

.planButton {
  @apply w-56;
}

.planPrice {
  @apply text-xs text-secondaryText text-center;
}

.optionButton {
  @apply text-lg border border-text py-1 px-4 rounded-xl mb-2 cursor-pointer;
}

.limitName {
  @apply text-secondaryText text-xs pr-2;
}

.limitValue {
  @apply text-secondaryText text-xs font-bold;
}

.selectedOption {
  @apply bg-primary;
}
